import React from 'react';
import { useLocation } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import {
  Card,
  CardMedia,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { personalInfoConverter } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';

const UserViewPage = () => {
  const { state } = useLocation();
  const { item } = state;
  const t = useTranslation();

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsUsers']} normalBack>
      <Grid container spacing={1} justifyContent="center" style={{ marginTop: 40 }}>
        {(item && item.attributes.avatar) ? (
          <Card elevation={3} style={{ borderRadius: 100 }}>
            <CardMedia image={`/api/media/users/${item.id}/${item.attributes.avatar}`} style={{ height: 100, width: 100, borderRadius: 100, overflow: 'hidden' }} />
          </Card>
        ) : <AccountCircle style={{ height: 100, width: 100 }} />}
      </Grid>
      <Grid container spacing={1} justifyContent="center" style={{ marginTop: 10 }}>
        {item && <strong>{item.name}</strong>}
      </Grid>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid md={5} marginTop={10}>
          {item && (
            <Paper elevation={3} style={{ padding: 10, paddingBottom: 0 }}>
              <h3 style={{ textAlign: 'center' }}>{t('personalInfo')}</h3>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('sharedName')}</TableCell>
                    <TableCell><strong>{item.name}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mail</TableCell>
                    <TableCell><strong>{item.email}</strong></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone</TableCell>
                    <TableCell><strong>{item.phone}</strong></TableCell>
                  </TableRow>
                  {Object.getOwnPropertyNames(item.attributes).filter((it) => (it !== 'avatar' && it !== 'id')).map((property) => (
                    <TableRow key={property}>
                      <TableCell>{t(property)}</TableCell>
                      <TableCell><strong>{personalInfoConverter(property, item.attributes[property])}</strong></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default UserViewPage;
