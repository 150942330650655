import React, { useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import RouteReportPage from './reports/RouteReportPage';
import ServerPage from './settings/ServerPage';
import UsersPage from './settings/UsersPage';
import DevicePage from './settings/DevicePage';
import UserPage from './settings/UserPage';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/GroupsPage';
import GroupPage from './settings/GroupPage';
import PositionPage from './other/PositionPage';
import NetworkPage from './other/NetworkPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './other/ReplayPage';
import TripReportPage from './reports/TripReportPage';
import StopReportPage from './reports/StopReportPage';
import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';
import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import CalendarsPage from './settings/CalendarsPage';
import CalendarPage from './settings/CalendarPage';
import ComputedAttributesPage from './settings/ComputedAttributesPage';
import ComputedAttributePage from './settings/ComputedAttributePage';
import MaintenancesPage from './settings/MaintenancesPage';
import MaintenancePage from './settings/MaintenancePage';
import CommandsPage from './settings/CommandsPage';
import CommandPage from './settings/CommandPage';
import StatisticsPage from './reports/StatisticsPage';
import RegisterPage from './login/RegisterPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import GeofencesPage from './other/GeofencesPage';
import GeofencePage from './settings/GeofencePage';
import useQuery from './common/util/useQuery';
import { useEffectAsync } from './reactHelper';
import { devicesActions } from './store';
import EventPage from './other/EventPage';
import PreferencesPage from './settings/PreferencesPage';
import AccumulatorsPage from './settings/AccumulatorsPage';
import CommandSendPage from './settings/CommandSendPage';
import App from './App';
import ChangeServerPage from './other/ChangeServerPage';
import DevicesPage from './settings/DevicesPage';
import PaymentsPage from './settings/PaymentsPage';
import PaymentPage from './settings/PaymentPage';
import LoginPage from './login/LoginPage';
import MainPageAlt from './main/MainPageAlt';
import PricesPage from './settings/PricesPage';
import PricePage from './settings/PricePage';
import RidesPage from './settings/RidesPage';
import RidePage from './settings/RidePage';
import VouchersPage from './settings/VouchersPage';
import VoucherPage from './settings/VoucherPage';
import ScheduledPage from './reports/ScheduledPage';
import PhotosPage from './settings/PhotosPage';
import RidesReportPage from './reports/RidesReportPage';
import CreditsPage from './settings/CreditsPage';
import CreditPage from './settings/CreditPage';
import RentManagerPage from './settings/RentManagerPage';
import RentAdminPage from './settings/RentAdminPage';
import AdvancedReportPage from './reports/AdvancedReportPage';
import UserViewPage from './settings/UserViewPage';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirectsHandled, setRedirectsHandled] = useState(false);

  const { pathname } = useLocation();
  const query = useQuery();

  useEffectAsync(async () => {
    if (query.get('token')) {
      const token = query.get('token');
      await fetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate(pathname);
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await fetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.select(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/');
    } else if (query.get('eventId')) {
      const eventId = parseInt(query.get('eventId'), 10);
      navigate(`/event/${eventId}`);
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return <LinearProgress />;
  }
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/change-server" element={<ChangeServerPage />} />
      <Route path="/" element={<App />}>
        <Route index element={<MainPageAlt />} />
        <Route path="/:id" element={<MainPageAlt />} />

        <Route path="position/:id" element={<PositionPage />} />
        <Route path="network/:positionId" element={<NetworkPage />} />
        <Route path="event/:id" element={<EventPage />} />
        <Route path="replay" element={<ReplayPage />} />
        <Route path="geofences" element={<GeofencesPage />} />

        <Route path="accumulators/:deviceId" element={<AccumulatorsPage />} />
        <Route path="calendars" element={<CalendarsPage />} />
        <Route path="calendar/:id" element={<CalendarPage />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="commands" element={<CommandsPage />} />
        <Route path="command/:id" element={<CommandPage />} />
        <Route path="command" element={<CommandPage />} />
        <Route path="command-send/:deviceId" element={<CommandSendPage />} />
        <Route path="credits" element={<CreditsPage />} />
        <Route path="credit" element={<CreditPage />} />
        <Route path="credit/:id" element={<CreditPage />} />
        <Route path="attributes" element={<ComputedAttributesPage />} />
        <Route path="attribute/:id" element={<ComputedAttributePage />} />
        <Route path="attribute" element={<ComputedAttributePage />} />
        <Route path="devices" element={<DevicesPage />} />
        <Route path="device/:id" element={<DevicePage />} />
        <Route path="device" element={<DevicePage />} />
        <Route path="drivers" element={<DriversPage />} />
        <Route path="driver/:id" element={<DriverPage />} />
        <Route path="driver" element={<DriverPage />} />
        <Route path="geofence/:id" element={<GeofencePage />} />
        <Route path="geofence" element={<GeofencePage />} />
        <Route path="groups" element={<GroupsPage />} />
        <Route path="group/:id" element={<GroupPage />} />
        <Route path="group" element={<GroupPage />} />
        <Route path="maintenances" element={<MaintenancesPage />} />
        <Route path="maintenance/:id" element={<MaintenancePage />} />
        <Route path="maintenance" element={<MaintenancePage />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="notification/:id" element={<NotificationPage />} />
        <Route path="notification" element={<NotificationPage />} />
        <Route path="payments" element={<PaymentsPage />} />
        <Route path="payment/:id" element={<PaymentPage />} />
        <Route path="payment" element={<PaymentPage />} />
        <Route path="photos/:id" element={<PhotosPage />} />
        <Route path="prices" element={<PricesPage />} />
        <Route path="price/:id" element={<PricePage />} />
        <Route path="price" element={<PricePage />} />
        <Route path="rides" element={<RidesPage />} />
        <Route path="ride/:id" element={<RidePage />} />
        <Route path="rentAdmin" element={<RentAdminPage />} />
        <Route path="rentManager" element={<RentManagerPage />} />
        <Route path="preferences" element={<PreferencesPage />} />
        <Route path="server" element={<ServerPage />} />
        <Route path="users" element={<UsersPage />} />
        <Route path="user/:id" element={<UserPage />} />
        <Route path="user" element={<UserPage />} />
        <Route path="userView" element={<UserViewPage />} />
        <Route path="vouchers" element={<VouchersPage />} />
        <Route path="voucher" element={<VoucherPage />} />

        <Route path="reports">
          <Route path="chart" element={<ChartReportPage />} />
          <Route path="rides" element={<RidesReportPage />} />
          <Route path="advanced" element={<AdvancedReportPage />} />
          <Route path="event" element={<EventReportPage />} />
          <Route path="route" element={<RouteReportPage />} />
          <Route path="scheduled" element={<ScheduledPage />} />
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="stop" element={<StopReportPage />} />
          <Route path="summary" element={<SummaryReportPage />} />
          <Route path="trip" element={<TripReportPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Navigation;
