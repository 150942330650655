import React, { useEffect, useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, IconButton,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Publish } from '@mui/icons-material';
import { useEffectAsync } from '../reactHelper';
import { formatBoolean, formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useAdministrator, useSuperManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { voltToPercentage } from '../common/util/converter';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  offline: {
    color: '#FF0000',
  },
  online: {
    color: theme.palette.primary.main,
  },
}));

const DevicesPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const positions = useSelector((state) => state.session.positions);

  const admin = useAdministrator();
  const superManager = useSuperManager();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [items, setItems] = useState([]);
  const [devices, setDevices] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleShowMap = (itemId) => {
    navigate(`/${itemId}`);
  };

  const showMap = {
    title: t('mapTitle'),
    icon: (<MapIcon fontSize="small" />),
    handler: handleShowMap,
  };

  function addBatteryOnDevices() {
    if (devices) {
      devices.forEach((device) => {
        Object.entries(positions).forEach((position) => {
          if (parseInt(position[0], 10) === device.id) {
            if (position[1].attributes.batteryLevel > 100 || !position[1].attributes.batteryLevel) {
              device.batteryLevel = voltToPercentage(position[1].attributes.power, device.attributes.batteryVoltage);
              if (device.batteryLevel < 0) {
                device.batteryLevel = 0;
              }
            } else {
              device.batteryLevel = position[1].attributes.batteryLevel;
            }
            device.lastUpdate = position[1].serverTime;
            device.blocked = position[1].attributes.blocked;
          }
        });
      });
      setItems([...devices]);
    }
  }

  useEffect(() => {
    addBatteryOnDevices();
  }, [devices, positions]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setDevices(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['devices']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            {superManager && <TableCell className={classes.columnAction} />}
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('deviceStatus')}</TableCell>
            <TableCell>{t('positionBattery')}</TableCell>
            <TableCell>{t('deviceCategory')}</TableCell>
            <TableCell>{t('positionBlocked')}</TableCell>
            <TableCell>{t('deviceLastUpdate')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              {superManager &&
                (
                  <TableCell className={classes.columnAction} padding="none">
                    <IconButton size="small" onClick={() => navigate(`/command-send/${item.id}`)}>
                      <Publish />
                    </IconButton>
                  </TableCell>
                )}
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  itemId={item.id}
                  editPath={superManager ? '/device' : null}
                  endpoint="devices"
                  setTimestamp={setTimestamp}
                  customAction={showMap}
                  noDelete={!admin}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className={item.status !== 'online' ? classes.offline : classes.online}>{item.status}</TableCell>
              <TableCell className={(item.batteryLevel < 40 || !item.batteryLevel) ? classes.offline : classes.online}>
                {`${item.batteryLevel ? item.batteryLevel : 0} %`}
              </TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{formatBoolean(item.blocked, t)}</TableCell>
              <TableCell>{formatTime(item.lastUpdate, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      {admin && (<CollectionFab editPath="/device" />)}
    </PageLayout>
  );
};

export default DevicesPage;
