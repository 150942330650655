import React, { useEffect, useState } from 'react';
import { CardActionArea } from '@mui/material';
import InfoCard from './InfoCard';
import { voltToPercentage } from '../util/converter';

const CardWrapper = ({ devices, positions, rents, type, callback }) => {
  const [value, setValue] = useState(0);
  const [filtered, setFiltered] = useState([]);

  function returnFiltered() {
    callback(filtered);
  }

  function countRents() {
    if (!rents) {
      setValue(0);
      return;
    }
    let counterRents = 0;
    const filteredSupport = [];
    rents.forEach((rent) => {
      const dateEnd = rent.dateEnd.split('-');
      if (parseInt(dateEnd[1], 10) < 2000) {
        counterRents += rent.deviceIds.length;
        filteredSupport.push(rent);
      }
    });
    setFiltered(filteredSupport);
    setValue(counterRents);
  }

  function countLowBattery() {
    if (!positions && !devices) {
      setValue(0);
      return;
    }
    let counterLowBattery = 0;
    const filteredSupport = [];
    Object.entries(positions).forEach((value) => {
      let batteryLevel = 0;
      if (value[1].attributes.batteryLevel) {
        batteryLevel = value[1].attributes.batteryLevel;
      }
      if (batteryLevel > 100 || !batteryLevel) {
        batteryLevel = voltToPercentage(value[1].attributes.power, devices[value[1].deviceId].attributes.batteryVoltage);
      }
      if (batteryLevel < 40) {
        counterLowBattery += 1;
        filteredSupport.push(value[1]);
      }
    });
    setFiltered(filteredSupport);
    setValue(counterLowBattery);
  }

  function countOnline() {
    if (!devices) {
      setValue(0);
      return;
    }
    let counterOnline = 0;
    const filteredSupport = [];
    Object.entries(devices).forEach((value) => {
      if (value[1].status === 'online') {
        counterOnline += 1;
        filteredSupport.push(value[1]);
      }
    });
    setFiltered(filteredSupport);
    setValue(counterOnline);
  }

  function countOffline() {
    if (!devices) {
      setValue(0);
      return;
    }
    let counterOffline = 0;
    const filteredSupport = [];
    Object.entries(devices).forEach((value) => {
      if (value[1].status !== 'online') {
        counterOffline += 1;
        filteredSupport.push(value[1]);
      }
    });
    setFiltered(filteredSupport);
    setValue(counterOffline);
  }

  useEffect(() => {
    switch (type) {
      case 'online':
        countOnline();
        break;
      case 'offline':
        countOffline();
        break;
      case 'lowBattery':
        countLowBattery();
        break;
      case 'inRide':
        countRents();
        break;
      default:
        break;
    }
  }, [devices, positions]);

  return (
    <CardActionArea onClick={() => returnFiltered()}>
      <InfoCard name={type} data={value} />
    </CardActionArea>
  );
};

export default CardWrapper;
