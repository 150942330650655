import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  discount25: {
    name: 'discount25',
    type: 'number',
  },
  'discount6+': {
    name: 'discount6+',
    type: 'number',
  },
}), [t]);
