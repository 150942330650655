import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, IconButton, Grid, Snackbar, Modal, Card,
} from '@mui/material';
import { Paid, PhotoLibrary, Stop } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import TableShimmer from '../common/components/TableShimmer';
import useReportStyles from '../reports/common/useReportStyles';
import ReportFilter from '../reports/components/ReportFilter';
import { useCatch } from '../reactHelper';
import InfoCard from '../common/components/InfoCard';
import { useAdministrator, useManager, useSuperManager } from '../common/util/permissions';
import { IVA, fixedFee, percentageFees } from '../common/util/stripePercentages';
import { snackBarDurationLongMs } from '../common/util/duration';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import PhotosPage from './PhotosPage';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
    padding: 5,
    overflow: 'scroll',
  },
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  offline: {
    color: '#FF0000',
  },
  online: {
    color: theme.palette.primary.main,
  },
}));

const RidesPage = () => {
  const classes = useStyles();
  const classesReport = useReportStyles();
  const t = useTranslation();

  const currentUser = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [netTotalAmount, setNetTotalAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openPhoto, setOpenPhoto] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const admin = useAdministrator();
  const superManager = useSuperManager();
  const manager = useManager();

  const NULL_DATE = 600000;

  const total = async (items, userId) => {
    let total = 0;
    let totalCredits = 0;
    let stripeFees = 0;
    items.forEach((item) => {
      if (item.payments.length > 0) {
        item.payments.forEach((payment) => {
          if (payment.status === 'succeeded') {
            if (payment.idPayment !== 'credit') {
              const amount = payment.amount / 100;
              total += amount;
              let stripeFee = ((amount * percentageFees) / 100) + fixedFee;
              stripeFee += ((stripeFee * IVA) / 100);
              stripeFees += stripeFee;
            } else {
              totalCredits += payment.amount / 100;
            }
          }
        });
      }
    });
    setTotalAmount(total);
    setCreditAmount(Math.round(totalCredits * 100) / 100);

    if (superManager) {
      try {
        const response = await fetch(`/api/users/${userId}`);
        if (response.ok) {
          const resJson = await response.json();
          const totalManagerPercentage = ((total * resJson.attributes.contractPercentage) / 100);
          setNetTotalAmount(Math.round((totalManagerPercentage - stripeFees) * 100) / 100);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        throw Error(error);
      }
    }

    if (!admin && !superManager && manager) {
      const totalManagerPercentage = ((total * currentUser.attributes.contractPercentage) / 100);
      setNetTotalAmount(Math.round((totalManagerPercentage - stripeFees) * 100) / 100);
    }
  };

  const forcePayment = async (ride) => {
    if (!window.confirm(`${t('areUSure')} : ${t('forcePayment')}?`)) {
      return;
    }

    const query = new URLSearchParams({ ride });

    const response = await fetch('/api/rent/mailpayment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: query,
    });
    if (response.ok) {
      setOpenSnack(true);
    } else {
      throw Error(await response.text());
    }
  };

  const forceStop = async (ride) => {
    if (!window.confirm(`${t('areUSure')} : ${t('forceStop')}?`)) {
      return;
    }

    const query = new URLSearchParams({ ride });

    const response = await fetch('/api/rent/forcestop', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: query,
    });
    if (response.ok) {
      setOpenSnack(false);
    } else {
      throw Error(await response.text());
    }
  };

  const handleSubmit = useCatch(async ({ from, to, userId, type }) => {
    const query = new URLSearchParams({ from, to, userId });
    if (type === 'export') {
      window.location.assign(`/api/reportsext/rents/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reportsext/rents/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reportsext/rents?${query.toString()}`);
        if (response.ok) {
          const resJson = await response.json();
          setItems(resJson);
          total(resJson, userId);
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['rides']}>
      <Modal open={openPhoto} onClose={() => setOpenPhoto(false)}>
        <Card elevation={3} className={classes.modal}>
          <PhotosPage id={selectedId} />
        </Card>
      </Modal>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <div className={classesReport.containerMain}>
        <div className={classesReport.header}>
          <ReportFilter ignoreDevice handleSubmit={handleSubmit} includeManagers />
        </div>
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={4} paddingTop={0}>
            <InfoCard name={`${t('total')} ${t('gross')}`} data={`${totalAmount} €`} />
          </Grid>
          <Grid item xs={4} paddingTop={0}>
            <InfoCard name={`${t('total')} ${t('net')}`} data={`${netTotalAmount} €`} />
          </Grid>
          <Grid item xs={4} paddingTop={0}>
            <InfoCard name={`${t('total')} ${t('credits')}`} data={`${creditAmount} C`} />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {admin && <TableCell className={classes.columnAction} />}
              {superManager && <TableCell className={classes.columnAction} />}
              <TableCell className={classes.columnAction} />
              <TableCell>{t('dateStart')}</TableCell>
              <TableCell>{t('dateEnd')}</TableCell>
              <TableCell>{`${t('reportDuration')} (min)`}</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>{t('sharedName')}</TableCell>
              <TableCell>{t('devices')}</TableCell>
              <TableCell>{t('voucher')}</TableCell>
              <TableCell>{t('price')}</TableCell>
              <TableCell>{t('paymentStatus')}</TableCell>
              {admin && <TableCell>{t('rentStatus')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
              (item.rideStatus !== 'pending' || admin) &&
              (
              <TableRow key={item.rideId}>
                {admin && (
                  <TableCell className={classes.columnAction} padding="none">
                    {Date.parse(item.dateEnd) === NULL_DATE ? (
                      <IconButton size="small" onClick={() => forceStop(item.rideId)}>
                        <Stop />
                      </IconButton>
                    ) : null}
                  </TableCell>
                )}
                {superManager && (
                  <TableCell className={classes.columnAction} padding="none">
                    {Date.parse(item.dateEnd) === NULL_DATE ? (
                      <IconButton size="small" onClick={() => forcePayment(item.rideId)}>
                        <Paid />
                      </IconButton>
                    ) : null}
                  </TableCell>
                )}
                <TableCell className={classes.columnAction} padding="none">
                  <IconButton size="small" onClick={() => { setSelectedId(item.rideId); setOpenPhoto(true); }}>
                    <PhotoLibrary />
                  </IconButton>
                </TableCell>
                <TableCell>{formatTime(item.dateStart, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
                <TableCell>{Date.parse(item.dateEnd) === NULL_DATE ? t('inRide') : formatTime(item.dateEnd, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
                <TableCell>{Date.parse(item.dateEnd) === NULL_DATE ? t('inRide') : item.duration}</TableCell>
                <TableCell>{item.rentUserEmail}</TableCell>
                <TableCell>{item.rentUsername}</TableCell>
                <TableCell>{item.deviceNames.toString()}</TableCell>
                <TableCell>{item.voucher}</TableCell>
                <TableCell>{item.payments.length > 0 ? item.payments.map((payment) => (<div>{`${payment.amount / 100} ${payment.idPayment === 'credit' ? 'C' : '€'}`}</div>)) : ''}</TableCell>
                {item.payments.length > 0 ? <TableCell>{item.payments.map((payment) => (<div className={payment.status === 'succeeded' ? classes.online : classes.offline}>{payment.status}</div>))}</TableCell> : <TableCell />}
                {admin && <TableCell>{item.rideStatus}</TableCell>}
              </TableRow>
              )
            )) : (<TableShimmer columns={7} startAction />)}
          </TableBody>
        </Table>
        <Snackbar
          message={t('opCompleted')}
          autoHideDuration={snackBarDurationLongMs}
          open={openSnack}
          onClose={() => setOpenSnack(false)}
        />
      </div>
    </PageLayout>
  );
};

export default RidesPage;
