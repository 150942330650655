export default class ReportObjects {
  daysOfWeek = [
    {
      name: 'Domenica',
      value: 0,
    },
    {
      name: 'Lunedì',
      value: 0,
    },
    {
      name: 'Martedì',
      value: 0,
    },
    {
      name: 'Mercoledì',
      value: 0,
    },
    {
      name: 'Giovedì',
      value: 0,
    },
    {
      name: 'Venerdì',
      value: 0,
    },
    {
      name: 'Sabato',
      value: 0,
    },
  ];

  typeRent = [
    {
      name: 'Ore/Minuti',
      value: 0,
    },
    {
      name: 'Giornaliere',
      value: 0,
    },
  ];

  numberRentVehicles = [
    {
      name: 'Singolo veicolo',
      value: 0,
    },
    {
      name: 'Multipli veicoli',
      value: 0,
    },
  ];

  finalUsersRent = [
    {
      name: 'Utenti che hanno noleggiato',
      value: 0,
    },
    {
      name: 'Utenti che non hanno noleggiato',
      value: 0,
    },
  ];

  vehiclesRented = [
    {
      name: 'bycicle',
      value: 0,
    },
    {
      name: 'scooter',
      value: 0,
    },
  ];
}
