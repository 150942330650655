import React from 'react';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import BuildIcon from '@mui/icons-material/Build';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import PublishIcon from '@mui/icons-material/Publish';
import SensorsIcon from '@mui/icons-material/Sensors';
import {
  AddBusiness,
  BikeScooter,
  BookmarkAdd,
  Description,
  Discount,
  Euro,
  Logout,
  Money,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { sessionActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator,
  useRestriction,
  useSuperManager,
} from '../../common/util/permissions';
import { nativePostMessage } from '../../common/components/NativeInterface';
import palette from '../../common/theme/palette';

const MenuItem = ({ title, link, icon, selected }) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const superManager = useSuperManager();
  const userId = useSelector((state) => state.session.user.id);
  const user = useSelector((state) => state.session.user);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = async () => {
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens:
              tokens.length > 1
                ? tokens.filter((it) => it !== notificationToken).join(',')
                : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };

  return (
    <>
      <List>
        {!readonly && (
          <>
            <MenuItem
              title={t('devices')}
              link="/devices"
              icon={<SensorsIcon />}
              selected={location.pathname.startsWith('/devices')}
            />
            <MenuItem
              title={t('reportTitle')}
              link="/reports/rides"
              icon={<Description />}
              selected={location.pathname.startsWith('/reports/rides')}
            />
            <MenuItem
              title={t('rides')}
              link="/rides"
              icon={<BikeScooter />}
              selected={location.pathname.startsWith('/rides')}
            />
            {admin && (
              <MenuItem
                title={t('rentAdmin')}
                link="/rentAdmin"
                icon={<AddBusiness />}
                selected={location.pathname.startsWith('/rentAdmin')}
              />
            )}
            <MenuItem
              title={t('rentManager')}
              link="/rentManager"
              icon={<BookmarkAdd />}
              selected={location.pathname.startsWith('/rentManager')}
            />
            {superManager && (
              <MenuItem
                title={t('prices')}
                link="/prices"
                icon={<Euro />}
                selected={location.pathname.startsWith('/prices')}
              />
            )}
            {superManager && (
              <MenuItem
                title={t('credits')}
                link="/credits"
                icon={<Money />}
                selected={location.pathname.startsWith('/credits')}
              />
            )}
            <MenuItem
              title={t('vouchers')}
              link="/vouchers"
              icon={<Discount />}
              selected={location.pathname.startsWith('/vouchers')}
            />
            <MenuItem
              title={t('settingsUser')}
              link={`/user/${userId}`}
              icon={<PersonIcon />}
              selected={location.pathname === `/user/${userId}`}
            />
            {superManager && (
              <MenuItem
                title={t('sharedGeofences')}
                link="/geofences"
                icon={<CreateIcon />}
                selected={location.pathname.startsWith('/geofence')}
              />
            )}
            {admin && (
              <MenuItem
                title={t('settingsGroups')}
                link="/groups"
                icon={<FolderIcon />}
                selected={location.pathname.startsWith('/group')}
              />
            )}
            {admin && (
              <MenuItem
                title={t('sharedDrivers')}
                link="/drivers"
                icon={<PersonIcon />}
                selected={location.pathname.startsWith('/driver')}
              />
            )}
            {admin && (
              <MenuItem
                title={t('sharedCalendars')}
                link="/calendars"
                icon={<TodayIcon />}
                selected={location.pathname.startsWith('/calendar')}
              />
            )}
            {admin && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/attributes"
                icon={<StorageIcon />}
                selected={location.pathname.startsWith('/attribute')}
              />
            )}
            {superManager && (
              <MenuItem
                title={t('sharedMaintenance')}
                link="/maintenances"
                icon={<BuildIcon />}
                selected={location.pathname.startsWith('/maintenance')}
              />
            )}
            {superManager && (
              <MenuItem
                title={t('sharedSavedCommands')}
                link="/commands"
                icon={<PublishIcon />}
                selected={location.pathname.startsWith('/command')}
              />
            )}
            {admin && (
              <MenuItem
                title={t('sharedNotifications')}
                link="/notifications"
                icon={<NotificationsIcon />}
                selected={location.pathname.startsWith('/notification')}
              />
            )}
          </>
        )}
        <MenuItem
          title={t('sharedPreferences')}
          link="/preferences"
          icon={<SettingsIcon />}
          selected={location.pathname === '/preferences'}
        />
        <Button
          type="reset"
          onClick={handleLogout}
          style={{
            all: 'unset',
            backgroundColor: palette.colors.negative,
            color: palette.colors.white,
          }}
        >
          <MenuItem
            title={t('loginLogout')}
            icon={<Logout style={{ color: palette.colors.white }} />}
          />
        </Button>
      </List>
      {superManager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t('settingsServer')}
                link="/server"
                icon={<StorageIcon />}
                selected={location.pathname === '/server'}
              />
            )}
            <MenuItem
              title={t('settingsUsers')}
              link="/users"
              icon={<PeopleIcon />}
              selected={
                location.pathname.startsWith('/user') &&
                location.pathname !== `/user/${userId}`
              }
            />
            {phone && <MenuItem />}
            {phone && <MenuItem />}
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
