import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  cardContent: {
    padding: 0,
  },
  text: {
    textAlign: 'center',
    fontSize: 20,
  },
}));

const InfoCard = ({ name, data }) => {
  const classes = useStyles();

  return (
    <Card elevation={3}>
      <CardHeader title={name} titleTypographyProps={{ align: 'center' }} />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.text}>{data}</Typography>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
