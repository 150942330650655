import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Modal,
  Skeleton,
} from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Download,
  ZoomIn,
} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
  },
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'contain',
  },
  downloadButton: {
    position: 'absolute',
    top: 0,
    right: 50,
    zIndex: 2,
    width: 60,
    height: 60,
  },
  zoomInButton: {
    position: 'absolute',
    top: 0,
    right: 120,
    zIndex: 2,
    width: 60,
    height: 60,
  },
  rightButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    border: 0,
    width: 50,
    backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
    zIndex: 2,
  },
  leftButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    border: 0,
    width: 50,
    backgroundColor: 'hsla(0, 0%, 0%, 0.3)',
    zIndex: 2,
  },
}));

const PhotosPage = ({ id }) => {
  const t = useTranslation();
  const [ride, setRide] = useState(null);
  const [afters, setAfters] = useState([]);
  const [befores, setBefores] = useState([]);
  const [photoOpened, setPhotoOpened] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState();
  const [photoList, setPhotoList] = useState([]);
  const classes = useStyles();

  const initPhotos = (ride) => {
    const beforesSupport = [];
    const aftersSupport = [];
    const photoListSupport = [];
    Object.entries(ride.attributes).forEach((attribute) => {
      const selector = attribute[0].split('_');
      if (selector[0] === 'before') {
        beforesSupport.push(attribute[1]);
        photoListSupport.push(attribute[1]);
      } else if (selector[0] === 'after') {
        aftersSupport.push(attribute[1]);
        photoListSupport.push(attribute[1]);
      }
    });
    setPhotoList(photoListSupport);
    setBefores(beforesSupport);
    setAfters(aftersSupport);
  };

  const handleOpenPhoto = (photo) => {
    setIndexPhoto(photoList.indexOf(photo));
    setPhotoOpened(true);
  };

  useEffectAsync(async () => {
    try {
      const response = await fetch(`/api/rides/admin/${id}`);
      if (response.ok) {
        const resRide = await response.json();
        setRide(resRide);
        initPhotos(resRide);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  }, []);

  return (
    <div>
      <Modal open={photoOpened} onClose={() => setPhotoOpened(false)}>
        <Card elevation={3} className={classes.modal}>
          <Button variant="contained" className={classes.downloadButton} href={`/api/media/${photoList[indexPhoto]}`} download>
            <Download />
          </Button>
          <Button variant="contained" className={classes.zoomInButton} href={`/api/media/${photoList[indexPhoto]}`} target="_blank">
            <ZoomIn />
          </Button>
          <CardMedia image={`/api/media/${photoList[indexPhoto]}`} className={classes.modalContent} />
          <button
            name="button"
            type="button"
            className={classes.leftButton}
            onClick={() => {
              if (indexPhoto - 1 >= 0) setIndexPhoto((indexPhoto - 1) % (befores.length + afters.length));
            }}
          >
            <ArrowBackIos style={{ color: '#ffffff', marginLeft: 4 }} />
          </button>
          <button
            name="button"
            type="button"
            className={classes.rightButton}
            onClick={() => {
              if (indexPhoto + 1 < (befores.length + afters.length)) setIndexPhoto((indexPhoto + 1) % (befores.length + afters.length));
            }}
          >
            <ArrowForwardIos style={{ color: '#ffffff', marginLeft: 4 }} />
          </button>
        </Card>
      </Modal>
      {ride && ride.datestart}
      <br />
      <h3>{t('startRent')}</h3>
      <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
        {befores.length === 0 ?
          (
            <Grid item xs={3} paddingTop={0} marginRight={10}>
              <Skeleton variant="rectangular" width={200} height={200} />
            </Grid>
          )
          :
          befores.map((item) => (
            <Grid item xs={6} md={3} paddingTop={0} key={item}>
              <CardActionArea onClick={() => handleOpenPhoto(item)}>
                <Card elevation={3}>
                  <CardMedia image={`/api/media/${item}`} style={{ height: 200 }} />
                </Card>
              </CardActionArea>
            </Grid>
          ))}
      </Grid>
      <h3>{t('endRent')}</h3>
      <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
        {afters.length === 0 ?
          (
            <Grid item xs={3} paddingTop={0} marginRight={10}>
              <Skeleton variant="rectangular" width={200} height={200} />
            </Grid>
          )
          :
          afters.map((item) => (
            <Grid item xs={6} md={3} paddingTop={0} key={item}>
              <CardActionArea onClick={() => handleOpenPhoto(item)}>
                <Card elevation={3}>
                  <CardMedia image={`/api/media/${item}`} style={{ height: 200 }} />
                </Card>
              </CardActionArea>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default PhotosPage;
