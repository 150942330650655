import React, { useEffect, useState, useCallback } from 'react';
import {
  CardActionArea,
  Grid,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { devicesActions } from '../store';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from '../settings/components/SettingsMenu';
import CardWrapper from '../common/components/CardWrapper';
import MainMap from './MainMap';
import StatusCard from '../common/components/StatusCard';
import { useEffectAsync } from '../reactHelper';
import EventsDrawer from './EventsDrawer';
import InfoCard from '../common/components/InfoCard';

const MainPageAlt = () => {
  const main = 'mainPage';
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [eventsOpen, setEventsOpen] = useState(false);
  const [rents, setRents] = useState();
  const [rentsActives, setRentsActives] = useState(0);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const devices = useSelector((state) => state.devices.items);
  const user = useSelector((state) => state.session.user);
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);

  function getPositionsFromDevices(devices) {
    setFilteredDevices(devices);
    const supportPositions = [];
    devices.forEach((device) => {
      Object.entries(positions).forEach((position) => {
        if (parseInt(position[0], 10) === device.id) {
          supportPositions.push(position[1]);
        }
      });
    });
    setFilteredPositions(supportPositions);
  }

  function getRentsPositions() {
    const supportPositions = [];
    rents.forEach((rent) => {
      Object.entries(positions).forEach((position) => {
        rent.deviceIds.forEach((deviceId) => {
          if (parseInt(position[0], 10) === deviceId) {
            supportPositions.push(position[1]);
          }
        });
      });
    });
    setFilteredPositions(supportPositions);
  }

  function rentsConf(rents) {
    if (!rents) {
      setRentsActives(0);
      return;
    }
    let counterRents = 0;
    const filteredSupport = [];
    rents.forEach((rent) => {
      const dateEnd = rent.dateEnd.split('-');
      if (parseInt(dateEnd[0], 10) < 2000) {
        counterRents += rent.deviceIds.length;
        filteredSupport.push(rent);
      }
    });
    setRents(filteredSupport);
    setRentsActives(counterRents);
  }

  useEffectAsync(async () => {
    if (id) {
      try {
        const response = await fetch(`api/devices?id=${id}`);
        if (response.ok) {
          const res = await response.json();
          setFilteredDevices(res);
          getPositionsFromDevices(res);
        } else {
          throw Error(await response.text());
        }
      } catch (error) {
        throw Error(error);
      }
      return;
    }
    try {
      const response = await fetch('api/devices');
      if (response.ok) {
        const res = await response.json();
        setFilteredDevices(res);
        getPositionsFromDevices(res);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
    const from = moment().subtract(1, 'week').startOf('day').toISOString();
    const to = moment().endOf('day').toISOString();
    const userId = user.id;
    const query = new URLSearchParams({ from, to, userId });
    try {
      const responseRent = await fetch(`/api/reportsext/rents?${query.toString()}`);
      if (responseRent.ok) {
        const resRent = await responseRent.json();
        rentsConf(resRent);
      }
    } catch (error) {
      throw Error(error);
    }
  }, [location]);

  useEffect(() => {
    getPositionsFromDevices(filteredDevices);
  }, [positions]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={[main]}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper devices={devices} type="online" callback={(value) => getPositionsFromDevices(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper devices={devices} type="offline" callback={(value) => getPositionsFromDevices(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardWrapper positions={positions} devices={devices} type="lowBattery" callback={(value) => setFilteredPositions(value)} />
        </Grid>
        <Grid item xs={3} paddingTop={0}>
          <CardActionArea onClick={() => getRentsPositions()}>
            <InfoCard name="inRide" data={rentsActives} />
          </CardActionArea>
        </Grid>
      </Grid>
      <MainMap filteredPositions={filteredPositions} selectedPosition={selectedPosition} onEventsClick={onEventsClick} />
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {selectedDeviceId && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={selectedPosition}
          onClose={() => dispatch(devicesActions.select(null))}
          desktopPadding={theme.dimensions.drawerWidthDesktop}
        />
      )}
    </PageLayout>
  );
};

export default MainPageAlt;
