import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useEffectAsync } from '../reactHelper';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useAdministrator } from '../common/util/permissions';
import useReportStyles from '../reports/common/useReportStyles';
import ReportFilter from '../reports/components/ReportFilter';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const PaymentsPage = () => {
  const classes = useStyles();
  const classesReport = useReportStyles();
  const t = useTranslation();
  const navigate = useNavigate();

  const admin = useAdministrator();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleShowRideDetails = (itemId) => {
    navigate(`/ride/${itemId}`);
  };

  const handleSubmit = () => {};

  const showRideDetails = {
    title: t('details'),
    icon: (<Visibility fontSize="small" />),
    handler: handleShowRideDetails,
  };

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/rides?all=true');
      if (response.ok) {
        setItems([{
          id: 1,
          datestart: '2022-12-30 10:03:39',
          dateEnd: '2022-12-31 10:03:39',
        }]);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['payments']}>
      <div className={classesReport.containerMain}>
        <div className={classesReport.header}>
          <ReportFilter handleSubmit={handleSubmit} />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnAction} />
              <TableCell>{t('dataStart')}</TableCell>
              <TableCell>{t('dataEnd')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.map((item) => (
              <TableRow key={item.id}>
                <TableCell className={classes.columnAction} padding="none">
                  {admin &&
                    (
                    <CollectionActions
                      itemId={item.id}
                      editPath="/ride"
                      endpoint="rides"
                      setTimestamp={setTimestamp}
                      customAction={showRideDetails}
                    />
                    )}
                </TableCell>
                <TableCell>{formatTime(item.datestart, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
                <TableCell>{formatTime(item.dateEnd, 'DD/MM/YYYY - hh:mm:ss')}</TableCell>
              </TableRow>
            )) : (<TableShimmer columns={3} endAction />)}
          </TableBody>
        </Table>
      </div>
    </PageLayout>
  );
};

export default PaymentsPage;
